<script lang="ts">
	import type { ProductForCustomer } from "../../../../core/schema/Product.js";
	import type { ProductSize } from "../../../../core/schema/ProductSize.js";
	import { productSizeKeyMap } from "../../../../core/schema/ProductSize.js";
	import { defaultSize } from "../../../../core/schema/defaultSize.js";
	import { getProductSizes } from "../../../../core/schema/utils/getProductSizes.js";

	export let selectedSize: ProductSize;
	export let product: ProductForCustomer;
	export let isSizeAddedToCart = false;

	const heightClasses = ["h-[1.5rem]", "h-[1.625rem]", "h-[1.75rem]", "h-[2rem]"];
	const widthClasses = ["w-[1.5rem]", "w-[1.625rem]", "w-[1.75rem]", "w-[2rem]"];

	$: sizes = getProductSizes(product.basicPrice);

	function selectSize(size: string): void {
		selectedSize = size as ProductSize;
	}

	// eslint-disable-next-line no-warning-comments
	// FIXME remove this after upgrade to Svelte 5.
	/* eslint-disable @typescript-eslint/explicit-function-return-type */
</script>

<div class="flex flex-row items-end gap-2">
	{#each sizes as size, i}
		{#if product.basicPrice[productSizeKeyMap[size]]}
			{@const id = `${product.id}-${size}`}
			<span class="{heightClasses[i]} {widthClasses[i]} relative flex shrink-0">
				<input
					class="custom-radio {isSizeAddedToCart
						? '[&:checked+label]:!bg-tertiaryGreen [&:checked+label]:!border-tertiaryGreen [&:checked+label]:!pointer-events-none'
						: ''}"
					type="radio"
					value={size}
					{id}
					on:click={() => {
						selectSize(size);
					}}
					name="size"
					checked={size === defaultSize}
				/>
				<label for={id} class="{heightClasses[i]} {widthClasses[i]}">
					{size}
				</label>
			</span>
		{/if}
	{/each}
</div>
