import { showHeaderStore } from "../stores/showHeaderStore.js";

let timeout: number | null = null;

export function temporarilyShowHeader(time: number): void {
	if (timeout !== null) {
		clearTimeout(timeout);
	}

	showHeaderStore.set(true);

	timeout = window.setTimeout(() => {
		timeout = null;
		showHeaderStore.set(false);
	}, time);
}
