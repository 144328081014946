<script lang="ts">
	import { getProductPath } from "../../../../core/schema/paths/getProductPath.js";
	import Link from "../Link.svelte";
	import { getGlobalSettings } from "../../getGlobalSettings.js";
	import type { ProductForCustomer } from "../../../../core/schema/Product.js";
	import Button from "../Button.svelte";
	import { cartStore } from "../../stores/cart/cartStore.js";
	import { isProductForCustomerConfigurable } from "../../../../core/schema/utils/isProductForCustomerConfigurable.js";
	import cake from "../../../../core/assets/icons/cake.svg?raw";
	import check from "../../../../core/assets/icons/check.svg?raw";
	import ProductSizeInputs from "./ProductSizeInputs.svelte";
	import type { ProductSize } from "../../../../core/schema/ProductSize.js";
	import { getPriceForProductOfSize } from "../../../../core/schema/utils/getPriceForProductOfSize.js";
	import { defaultSize } from "../../../../core/schema/defaultSize.js";
	import { doesProductForCustomerHaveMultipleSizes } from "../../../../core/schema/utils/doesProductForCustomerHaveMultipleSizes.js";
	import ProductCardImage from "./ProductCardImage.svelte";
	import { formatPrice } from "../../../../core/schema/utils/formatPrice.js";
	import { nbspify } from "../../../../core/utils/nbspify.js";
	import ProductPriorityLabel from "./ProductPriorityLabel.svelte";
	import { reportAddedToCart } from "../../UniversalAnalytics.js";
	import { createEmptyOptions } from "../../../../core/schema/utils/createEmptyOptions.js";
	import NumberInput from "../Form/NumberInput.svelte";
	import Form from "../Form/Form.svelte";
	import { temporarilyShowHeader } from "../../utils/temporarilyShowHeader.js";

	export let product: ProductForCustomer;
	export let index = 0;

	const { TENANT_LOCALE, WEB_URL } = getGlobalSettings();

	let selectedSize: ProductSize = defaultSize;

	$: isInCart = $cartStore.order.products.some(
		({ product: { id }, options }) =>
			id === product.id && (doesProductForCustomerHaveMultipleSizes(product) ? options?.size === selectedSize : true),
	);

	$: price = getPriceForProductOfSize(product, selectedSize);

	async function putItemToCart(event: SubmitEvent): Promise<void> {
		const form = event.target as HTMLFormElement;
		const formData = new FormData(form);
		const quantity = Number(formData.get("quantity"));

		const defaultOptions = createEmptyOptions(selectedSize);
		const response = await cartStore.addItem(
			product,
			quantity,
			product.minimumOrderQuantity,
			isProductForCustomerConfigurable(product) || doesProductForCustomerHaveMultipleSizes(product)
				? defaultOptions
				: undefined,
		);
		reportAddedToCart(response.product, TENANT_LOCALE);
		temporarilyShowHeader(1500);
	}

	$: customizable = isProductForCustomerConfigurable(product);

	$: link = `/${getProductPath(TENANT_LOCALE, product)}`;

	function linkToProductOfSize(link: string, size: ProductSize): string {
		const url = new URL(`${WEB_URL}${link}`);
		url.searchParams.set("size", size);
		return `${url.pathname}${url.search}`;
	}

	function linkToProductConfig(link: string): string {
		const url = new URL(`${WEB_URL}${link}`);
		url.searchParams.set("config", "");
		return `${url.pathname}${url.search}`;
	}

	$: linkToProduct = doesProductForCustomerHaveMultipleSizes(product) ? linkToProductOfSize(link, selectedSize) : link;
</script>

<div
	class="group flex h-fit w-full flex-col rounded-[1.375rem] border border-transparent transition-all duration-300 min-[430px]:w-[initial] min-[430px]:basis-[calc(50%-0.5rem)] sm:basis-[calc(50%-0.25rem)] sm:p-4 sm:hover:border-gray-300 md:basis-[calc(50%-0.75rem)] lg:basis-[calc((100%-(2*.75rem))/3)] xl:basis-[calc((100%-(2*1.5rem))/3)] xl:p-6"
>
	<a
		href={linkToProduct}
		class="relative block aspect-square h-auto w-full overflow-hidden rounded-lg min-[430px]:min-h-[11.9375rem] min-[430px]:min-w-[11.9375rem]"
	>
		{#if product.priorityLabel !== undefined}
			<ProductPriorityLabel class="text-2xs absolute left-2 top-2 z-10 font-semibold" label={product.priorityLabel} />
		{/if}
		<ProductCardImage
			loading={index < 5 ? "eager" : "lazy"}
			{product}
			size={344}
			logo={{
				class:
					"min-w-[6rem] max-w-[28%] md:w-32 min-[430px]:min-w-[initial] min-[430px]:max-w-[40%] md:max-w-[initial] xl:w-[8.5rem]",
				size: 136,
			}}
		/>
	</a>
	<Form
		on:submit={putItemToCart}
		class="mt-4 grid h-[9.25rem] grid-rows-[repeat(3,1fr)] gap-2 min-[430px]:h-[11.5rem] min-[430px]:grid-rows-[1fr,1fr,2.25rem] md:h-[9.25rem] md:grid-rows-[repeat(3,1fr)] lg:h-[12rem] lg:grid-rows-[1fr,1fr,2.25rem] xl:h-[9.25rem] xl:grid-rows-[repeat(3,1fr)]"
	>
		<a
			class="text-md md:text-md line-clamp-2 h-14 font-semibold leading-7 min-[430px]:text-base min-[430px]:leading-[1.3] md:leading-[1.5]"
			href={linkToProduct}
		>
			{product.name[TENANT_LOCALE]}
		</a>
		<div
			class="mb-2 flex justify-between min-[430px]:mb-0 min-[430px]:flex-col md:mb-2 md:flex-row lg:mb-0 lg:flex-col xl:mb-2 xl:flex-row"
		>
			<div class="flex items-center gap-3 md:gap-2 lg:gap-3">
				<!-- INFO: Minimum order quantity wins over multiple sizes. -->
				{#if product.minimumOrderQuantity > 1}
					<div
						class="flex flex-wrap items-center gap-2"
						class:opacity-0={isInCart}
						class:pointer-events-none={isInCart}
					>
						<span class="text-xs">Počet kusů</span>
						<NumberInput
							id="quantity"
							required
							min={product.minimumOrderQuantity}
							max={999}
							class="h-9 w-32 p-1.5"
							value={product.minimumOrderQuantity}
						/>
					</div>
				{:else if doesProductForCustomerHaveMultipleSizes(product)}
					<span class="min-[430px]:text-2xs pt-[0.3125rem] text-xs font-normal leading-5 md:text-xs">Velikost</span>
					<ProductSizeInputs {product} isSizeAddedToCart={isInCart} bind:selectedSize />
				{/if}
			</div>
			<span
				class="text-md flex items-center leading-7 min-[430px]:mb-1 min-[430px]:mt-3 md:my-0 lg:mb-2 lg:mt-3 xl:my-0 {customizable ||
				product.minimumOrderQuantity > 1
					? 'justify-between'
					: 'justify-end'}"
			>
				{#if customizable}
					<Link
						class="text-2xs hidden !py-[.175rem] font-semibold min-[430px]:block md:hidden lg:block lg:text-xs xl:hidden"
						href={linkToProductConfig(linkToProduct)}
						asButton
						variant="secondary">Upravit</Link
					>
				{/if}
				<span class="font-semibold">
					{nbspify(formatPrice(price?.withTax ?? 0, TENANT_LOCALE, false))}
				</span>
			</span>
		</div>
		<div
			class="flex items-end gap-4 {customizable || product.minimumOrderQuantity > 1
				? 'justify-between'
				: 'justify-end'}"
		>
			{#if customizable}
				<Link
					class="min-[430px]:hidden md:block lg:hidden xl:block"
					href={linkToProductConfig(linkToProduct)}
					asButton
					variant="secondary"
				>
					Upravit
				</Link>
			{/if}
			<Button
				type="submit"
				variant="primary"
				class="whitespace-nowrap text-base font-semibold leading-5 min-[430px]:w-full min-[430px]:justify-center min-[430px]:text-xs md:w-fit md:justify-start md:text-base lg:w-full lg:justify-center xl:w-fit xl:justify-start {isInCart
					? '!bg-tertiaryGreen !border-tertiaryGreen hover:!bg-tertiaryGreen focus:!bg-tertiaryGreen active:!bg-tertiaryGreen hover:!border-tertiaryGreen focus:!border-tertiaryGreen active:!border-tertiaryGreen pointer-events-none !text-white hover:!text-white focus:!text-white active:!text-white'
					: ''}"
				iconClass="w-[0.875rem]"
				icon={isInCart ? check : cake}
			>
				<div class="together">
					<div class:opacity-0={isInCart}>Do krabice</div>
					<div class:opacity-0={!isInCart}>V krabici</div>
				</div>
			</Button>
		</div>
	</Form>
</div>

<style lang="postcss">
	.together {
		display: grid;
		grid-template-areas: "single";

		& > * {
			grid-area: single;
		}
	}
</style>
